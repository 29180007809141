import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/tip-1.png';
import { clearSiteData } from "../store/siteSlice";
import { googleLogout } from "@react-oauth/google";

function Navbar() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleLogout = () => {
    googleLogout();
    dispatch(logout());
    dispatch(clearSiteData());
    navigate('/');
  };

  return (
    <header className="sticky relative py-4 px-8 lg:px-16  bg-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between">
          {/* Logo */}
          <div className="shrink-0 flex">
            <a
              className="relative flex items-center sm:order-1 text-black flex-none text-xl font-bold focus:outline-none focus:opacity-80"
              onClick={() => navigate('/')}
            >
              <img className="absolute -top-[13px] -left-[28px] h-[50px]" src={Logo} alt="logo" />
              ipflow
            </a>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="flex lg:hidden">
            <button
              type="button"
              className="text-gray-900"
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
            >
              {!expanded ? (
                <svg
                  className="w-7 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="w-7 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Centered Links for Desktop */}
          <div className="hidden lg:absolute lg:inset-y-0 lg:flex lg:items-center lg:justify-center lg:space-x-12 lg:-translate-x-1/2 lg:left-1/2">
            <a
              onClick={() => navigate('/features')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Features
            </a>
            <a
              onClick={() => navigate('/pricing')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Pricing
            </a>
            <a
              onClick={() => navigate('/blog')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Blog
            </a>
            <a
              onClick={() => navigate('/b2b-community')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              B2B Community
            </a>
          </div>

          {/* Right-side Links and Buttons */}
          <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
            {!isLoggedIn ? (
              <>
                <a
                  onClick={handleLogin}
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
                >
                  Login
                </a>
                <a
                  onClick={handleSignUp}
                  className="px-5 py-2 text-base font-semibold leading-7 text-gray-900 transition-all duration-200 bg-transparent border border-gray-900 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white cursor-pointer"
                >
                  Join community
                </a>
              </>
            ) : (
              <a
                onClick={handleLogout}
                className="px-5 py-2 text-base font-semibold leading-7 text-gray-900 transition-all duration-200 bg-transparent border border-gray-900 rounded-xl font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white cursor-pointer"
              >
                Logout
              </a>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {expanded && (
          <nav className="flex flex-col mt-4 space-y-4 lg:hidden">
            <a
              onClick={() => navigate('/features')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Features
            </a>
            <a
              onClick={() => navigate('/pricing')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Pricing
            </a>
            <a
              onClick={() => navigate('/blog')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              Blog
            </a>
            <a
              onClick={() => navigate('/b2b-community')}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 cursor-pointer"
            >
              B2B Community
            </a>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Navbar;
