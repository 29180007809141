

import axios from 'axios';
import UserDetailsForm from '../components/userDetailsForm';

const AddSite = () => {
  // const [isClient, setIsClient] = useState(false);
  // const [userDetails, setUserDetails] = useState({});
  // const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  // const user = useAppSelector((state) => state.UserData.userData);
  // const router = useRouter();

  // useEffect(() => {
  //   setIsClient(true);

  //   if (!isLoggedIn) {
  //     router.push('/login');
  //   } else {
  //     const userId = decodeJWT(user)?.id;

  //     if (userId) {
  //       axios.get(`/api/post/${userId}`)
  //         .then((response) => {
  //           const data = response.data;
  //           if(data.message === 'UserDetails not found'){
  //               return
  //           }else{
  //               if(response.status === 200 && data.url !== ''){
  //                   router.push('/');
  //                   setUserDetails(data);
  //               }
  //           }
  //         })
  //         .catch((error) => {
  //           console.error('Error fetching user details:', error);
  //           router.push('/userDetails');
  //         });
  //     }
  //   }
  // }, [isLoggedIn, user, router]);

  // if (!isClient) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <div className=''>
        <UserDetailsForm />
        
      </div>
    </>
        
  );
};

export default AddSite;
