import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const site = useSelector((state) => state.site);

  // Function to handle navigation
  const handleNavigate = (route) => {
    navigate(route);
  };

  // Function to check if the current route is active
  const isActiveRoute = (route) => {
    return location.pathname === route ? 'bg-[#2d70f5] text-white' : 'text-gray-600 hover:text-gray-700';
  };

  return (
    <aside className="flex flex-col w-48 min-h-screen px-5 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l">
      {/* {site.websiteName !== null &&
        <div className='h-16 mb-4 bg-[#ecffc4] text-sm flex justify-center items-center'>
          Account : {site.websiteName} <br />
          website : {site.url}
        </div>
      } */}
      <div className="flex flex-col justify-between flex-1">

        <nav className="flex-1 -mx-3 space-y-2">
          <a
            onClick={() => handleNavigate('/home')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/home')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            <span className="mx-2 text-md font-medium">Home</span>
          </a>

          <a
            onClick={() => handleNavigate('/dashboard')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/dashboard')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
            </svg>
            <span className="mx-2 text-md font-medium">Dashboard</span>
          </a>

          <a
            onClick={() => handleNavigate('/projects')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/projects')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
            </svg>
            <span className="mx-2 text-md font-medium">Projects</span>
          </a>

          <a
            onClick={() => handleNavigate('/tasks')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/tasks')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
            </svg>
            <span className="mx-2 text-md font-medium">Tasks</span>
          </a>

          <a
            onClick={() => handleNavigate('/reporting')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/reporting')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
            <span className="mx-2 text-md font-medium">Reporting</span>
          </a>

          <a
            onClick={() => handleNavigate('/settings')}
            className={`flex items-center px-3 py-2 transition-colors duration-300 transform rounded ${isActiveRoute('/settings')}`}
            href="#"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 009.375-9.38 9.337 9.337 0 00-9.375-9.375 9.379 9.379 0 00-2.625.372A9.336 9.336 0 0015 19.128z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 3v3M3 12h3M21 12h3M12 21v-3" />
            </svg>
            <span className="mx-2 text-md font-medium">Settings</span>
          </a>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
