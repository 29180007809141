import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { useNavigate } from 'react-router-dom';
import { clearSiteData } from "../store/siteSlice";


function NavbarWt() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  
  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearSiteData());
    navigate('/');
  };

  return (
    <div>
      <header className="py-4 bg-white sm:py-2">
        <div className="mx-auto px-4  max-w-7xl xl:max-w-[90%] sm:px-6 lg:px-4">
          <div className="flex items-center justify-between">
            <div className="shrink-0">
              <a
                class="sm:order-1 text-black flex-none text-xl font-bold focus:outline-none focus:opacity-80"
                onClick={()=>navigate('/')}
              >
                Tipflow
              </a>
            </div>

            <div className="flex md:hidden">
              <button
                type="button"
                className="text-white"
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
              >
                {!expanded ? (
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>


            {!isLoggedIn ? (
              <>
                <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    href="#"
                    title=""
                    className="relative inline-flex items-center justify-center px-6 py-1 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                    onClick={handleLogin}
                  >
                    Login
                  </a>
                </div>
                <div className="relative hidden md:items-center md:justify-center md:inline-flex group ml-4">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    href="#"
                    title=""
                    className="relative inline-flex items-center justify-center px-6 py-1 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    href="#"
                    title=""
                    className="relative inline-flex items-center justify-center px-6 py-1 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                </div>
                
              </>
            )}
          </div>

          {expanded && (
            <nav>
              <div className="flex flex-col pt-8 pb-4 space-y-6">
                <a
                  href="#"
                  title=""
                  className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
                >
                  Products
                </a>
                <a
                  href="#"
                  title=""
                  className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
                >
                  Features
                </a>
                <a
                  href="#"
                  title=""
                  className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
                >
                  Pricing
                </a>
                <a
                  href="#"
                  title=""
                  className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
                >
                  Support
                </a>
                <div className="relative inline-flex items-center justify-center group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    href="#"
                    title=""
                    className="relative inline-flex items-center justify-center w-full px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                  >
                    Start free trial
                  </a>
                </div>
              </div>
            </nav>
          )}
        </div>
      </header>
    </div>
  );
}

export default NavbarWt;
